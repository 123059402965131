import React from "react";
import { useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";

export default function ToggleLayout({
  title,
  image,
  toggleIndex,
  visibleToggleIndex,
  onVisibleToggleIndexChange,
  children,
  dropDown,
  onPlotChange,
  Farm,
  value,
  imgType,
  feather,
  featherclass,
  print
}) {

  const dummyPlotList = [
    { id: 1, name: "Plot A" },
    { id: 2, name: "Plot B" },
    { id: 3, name: "Plot C" },
  ];

  // const plotList = useSelector(state => {
  //   return state.plotList.plotList;
  // });

  const { t, i18n } = useTranslation();
  const onPlotSelect = (e) => {
    onPlotChange(e.target.value);
    console.log(e.target.name, "name of plot");
  };

  return (
    <div className="single-card m-rl-m-8 plot-report-container">
      <div
        onClick={() => onVisibleToggleIndexChange(toggleIndex)}
        className={
          "row sa-cursor align-items-center px-3  " +
          (toggleIndex === visibleToggleIndex ? "toggle-border-bottom" : "")
        }
        style={{ height: "64px" }}
      >
        <div className={"col-auto"}>
          <div className={"image-container-toggle mr-3"}>
            {imgType === "img" && (
              <img
                src={image}
                width={"24px"}
                className={
                  toggleIndex === visibleToggleIndex
                    ? "toggle-icon-active"
                    : null
                }
              />
            )}
            {imgType === "feather" && (
              <FeatherIcon
                icon={feather}
                className={
                  featherclass +
                  " " +
                  (toggleIndex === visibleToggleIndex
                    ? "toggle-active-feather"
                    : null)
                }
              />
            )}
          </div>
          <div
            className={
              "toggle-title " +
              (toggleIndex === visibleToggleIndex
                ? "toggle-text-blue"
                : "text-dark-gray")
            }
          >
            {title}
          </div>
        </div>
        <div className={"ml-auto d-flex align-items-center col-auto"}>
          {!print && (
            <div className={"toggle-btn-container"}>
              <i
                className={
                  "fa " +
                  (toggleIndex === visibleToggleIndex
                    ? "fa-angle-up"
                    : "fa-angle-down")
                }
                color={"#91949E"}
              />
            </div>
          )}
        </div>
      </div>

      {toggleIndex === visibleToggleIndex && (
        <div className={"p-a-16"}>
          {children}
        </div>
      )}
    </div>
  );
}

ToggleLayout.defaultProps = {
  Farm: true,
  imgType: "img"
};
