import React from 'react';
import NavbarLayout from "../shared/components/navbarLayout";
import {useHistory, useLocation} from "react-router-dom";
import {getName, getRefCount, getRole, signOut} from "../shared/utils/SharedAuthentication";
import {useDispatch} from "react-redux";
import {toggleChangePassword} from "../shared/actions/setting";
import packageJson from "../../package.json";
import {useTranslation} from 'react-i18next';
import logoImage from "../images/SenzView.png";



export default function Navbar(props) {

  let history = useHistory();
  const {t, i18n} = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const dispatch = useDispatch();


  function text() {
    return <div>He</div>
  }

  function expandPageNotification() {
    history.push("/notification-page");
  }

  function brand() {
    return (
      <span onClick={() => history.push("/")} className="nav-logo">
      <img src={logoImage} alt="MAGMA PORTAL Logo"
           style={{ width: 'auto', height: '48px' }}
      />
    </span>
    );
  }

  function about() {
    window.open("https://senzagro.com", "_blank");
  }

  function showChangePassword() {
    dispatch(toggleChangePassword(true));

  }

  function logOut() {
    signOut();
    history.push("/login");
  }

  function onPaths(paths) {
    return (match, location) => {
      if (paths === "SF") {
        return (
          !location.pathname.match("/daily-update") &&
          !location.pathname.match("/cash-flow") &&
          !location.pathname.match("/settings") &&
          !location.pathname.match("/xindicate") &&
          !location.pathname.match("/map-data") &&
          !location.pathname.match("/corporate") &&
          !location.pathname.match("/system-config") &&
          !location.pathname.match("/dashboard") &&
          !location.pathname.match("/cco") &&
          !location.pathname.match("/notification-page")
        );
      }
      return location.pathname.match(paths);
    };
  }

  let navItems = [
    {
      title: "Dashboard",
      isActive: onPaths("/home"),
      to: "/home",
      permissions: true,
      webScreen: true,
      icon: "map",
    }
  ]

  let mobileNavbar = [
    {
      title: "Magma v" + packageJson.version,
      click: about,
      icon: "alert-circle"
    }, {
      title: "Change Password",
      click: showChangePassword,
      icon: "lock"
    }, {
      title: "Logout",
      click: logOut,
      icon: "log-out",
      logout: true
    }
  ]
  let txts = {
    language: "Select Language",
    notifications: "Notifications",
    emptyNotifications: "There are no notifications",
  }
  let languages = [
    {
      title: "English",
      lng: "en",
      icon: "E",
    }, {
      title:"தமிழ்",
      lng: "ta",
      icon: "T",
    }, {
      title: "සිංහල",
      lng: "si",
      icon: "S",
    }, {
      title: "日本語",
      lng: "ja",
      icon: "J",
    }
  ]


  return (
    <div>
      <NavbarLayout notificationDot={false} txts={txts} settingPermission={false}
                    notificationCard={text}
                    notificationActive={false}
                    expandPageNotification={expandPageNotification}
                    name={getName()} settingList={[]} languages={languages} changeLanguage={changeLanguage}
                    navItems={navItems} hideTranslation={true}
                    mobileNavbar={mobileNavbar} loadNotifications={[]} role={getRole()} refs={getRefCount()}
                    brand={() => brand()}/>
    </div>
  );
}

