import {createStore} from "redux";
import reducer from "./reducers";
import {TOGGLE} from "./utils/Enum";

const store = createStore(reducer, {
    setting: {
        toggle: TOGGLE.LIST,
        isChangePasswordVisible: false,
        isLoading: false,
        isLoadingCount: 0,
        confirmationDialog: {},
        timePicker: {},
        isLoaderCount: 0,
    },
});

export default store;