import React, { useEffect, useState } from "react";
import axios from 'axios';
import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import FormHandler from "../shared/utils/FormHandler";
import { validateAlert } from "../utils/FormValidationRules";
import * as _ from "underscore";
import InfiniteScroll from "react-infinite-scroll-component";
import InputRange from "react-input-range";
import { toggleLoader } from "../shared/actions/setting";
import { getUserId } from "../shared/utils/SharedAuthentication";
import { toast } from "react-toastify";

import { getDisplayName, toBrowserTimeZone, getSensorLimits } from "../shared/utils/utils";
import 'react-input-range/lib/css/index.css';

function SettingAlert(props) {

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { farmId } = useParams();
  const [isTable, setTable] = useState(true);
  const [isToggle, setIsToggle] = useState(true);
  const [thresholdHistory, setThresholdHistory] = useState([]);
  const [maintains, setMaintains] = useState({});
  const [maintainUpdate, setMaintainUpdate] = useState(false);
  const [persistence, setPersistence] = useState({});
  const [persistenceUpdate, setPersistenceUpdate] = useState(false);
  const [alertSwitch, setAlertSwitch] = useState({});
  const [alertUpdate, setAlertUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertHistory, setAlertHistory] = useState([]);
  const [levels, setLevels] = useState([]);
  const [sensorIndex, setSensorIndex] = useState(0);
  const [indexSensor, setIndexSensor] = useState(0);
  const [formIndex, setFormIndex] = useState(1);
  const [showThreshold, setShowThreshold] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [showData, setShowData] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [updateThreshold, setUpdateThreshold] = useState(true);
  const [updateAlert, setUpdateAlert] = useState(true);
  const [formData, setFormData] = useState([]);
  const [indexValueThreshold, setIndexValueThreshold] = useState(1);
  const [indexValueAlert, setIndexValueAlert] = useState(1);
  const [formError, setFormError] = useState(null);
  const [isMaintenanceHistory, setIsMaintenanceHistory] = useState([]);
  const [kitData, setKitData] = useState(null);
  const { values, errors, handleChange, handleSubmit, handleOnBlur, setValue } =
    FormHandler(saveAlertSchedule, validateAlert);

  const style1 = { borderBottom: "solid 2.5px #22a5c6" };

  function saveAlertSchedule() {
    setIsLoading(true);
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + props.kitId)
      .then(function (response) {
        setKitData(response.data.content);
        setIsLoading(false);
        setLevels(response.data.content);
      })
      .catch(function (error) {
      })
      .finally(() => {
      });

    // getHistoryByNumber(0, true);
  }, [props.kitId, alertUpdate, maintainUpdate, persistenceUpdate]);

  useEffect(() => {
    if (!props.kitId) {
      return;
    }
    dispatch(toggleLoader(true));
    setFormError(null);
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + props.kitId + `/property/` + sensorIndex + `/alert-limit`
      )
      .then((res) => {
        setFormData(res.data.content);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          setFormError(error.response.data.message);
        } else {
          toast.error(t("Something Went Wrong"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setResetForm(false);
        setShowData(true);
      });
  }, [props.kitId, resetForm, sensorIndex, updateData]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));

    if (kitData.properties[sensorIndex].code === "CN") {
      values.low = values.low / 1000;
      values.high = values.high / 1000;
    }
    axios.put(`${process.env.REACT_APP_HOST}/user/${getUserId()}/kit/${props.kitId}/property/${sensorIndex}/alert-limit`, values)
      .then((res) => {
        toast.success(t("Alert Successfully Updated"));
        setUpdateData(!updateData);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
        setUpdateThreshold(!updateThreshold);
      });
  }, [isLoading]);

  useEffect(() => {
    if (!props.kitId || !alertUpdate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        props.kitId +
        `/alert`,
        alertSwitch
      )
      .then((res) => {
        toast.success(res.data.content);
        setUpdateAlert(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setAlertUpdate(false);
        dispatch(toggleLoader(false));
      });
  }, [alertUpdate]);

  useEffect(() => {
    if (!props.kitId || !updateAlert) {
      return;
    }
    axios
      .get(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + props.kitId + `/alert/size/10/index/0`
      )
      .then((res) => {
        setAlertHistory(res.data.content);
      })
      .finally(() => {
        setUpdateAlert(false);
      });
  }, [props.kitId, updateAlert]);

  useEffect(() => {
    if (!props.kitId) {
      return;
    }

    dispatch(toggleLoader(true));
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        props.kitId +
        `/property/` +
        sensorIndex +
        `/alert-limit/history/size/10/index/0`
      )
      .then((res) => {
        setThresholdHistory(res.data.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        // setUpdateThreshold(false)
      });
  }, [props.kitId, sensorIndex, updateThreshold]);

  useEffect(() => {
    if (!props.kitId || !maintainUpdate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        props.kitId +
        `/maintain`,
        maintains
      )
      .then((res) => {
        toast.success(t("Successfully Maintains Updated"));
        setMaintainUpdate(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [maintainUpdate]);

  useEffect(() => {
    if (!props.kitId || !persistenceUpdate) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        props.kitId +
        `/persistence`,
        persistence
      )
      .then((res) => {
        toast.success(t("Successfully Persistence Updated"));
        setPersistenceUpdate(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [persistenceUpdate]);

  function fetchDataThreshold() {
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        props.kitId +
        `/property/` +
        sensorIndex +
        `/alert-limit/history/size/10/index/` +
        indexValueThreshold
      )
      .then((res) => {
        setThresholdHistory((old) => [...old, ...res.data.content]);
        setIndexValueThreshold(indexValueThreshold + 1);
      });
  }

  useEffect(() => {
    if (!props.kitId || !updateAlert) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/kit/' + props.kitId + `/maintain/size/` + 10 + `/index/0`)
      .then(res => {
        setIsMaintenanceHistory(res.data.content)
      }).catch((error) => {

      }).finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [props.kitId]);

  function fetchDataAlertHistory() {
    axios
      .get(
        process.env.REACT_APP_HOST +
        `/user/` +
        getUserId() +
        `/kit/` +
        props.kitId +
        `/alert/size/10/index/` +
        indexValueAlert
      )
      .then((res) => {
        setAlertHistory((old) => [...old, ...res.data.content]);
        setIndexValueAlert(indexValueAlert + 1);
      });
  }

  function getLimits() {
    if (!kitData || !kitData.properties) {
      return { min: 0, max: 100, step: 1 };
    }
    let sensor = kitData.properties[indexSensor].code;

    return getSensorLimits(sensor);
  }

  function handleRangeChange(value) {
    console.log(value)
    setValue({ low: value.min, high: value.max });
  }

  function underMaintainSwitch(checked) {
    setMaintains({maintain: checked});
    setMaintainUpdate(true);
  }

  function persistenceSwitch(checked) {
    setPersistence({persistence: checked});
    setPersistenceUpdate(true);
  }

  function setValues(level) {
    let selectedValue = _.where(formData, { level: level ? level : 1 })[0];
    console.log("selected value #######", selectedValue)
    values.currentLevelPeriod = _.result(selectedValue, "currentLevelPeriod");
    values.high = _.result(selectedValue, "high");
    values.low = _.result(selectedValue, "low");
    values.persistence = _.result(selectedValue, "persistence");
    values.nextLevelPeriod = _.result(selectedValue, "nextLevelPeriod");
  }

  useEffect(() => {
    setFormIndex(1);
  }, [sensorIndex]);

  useEffect(() => {
    if (!formData || !kitData) {
      return;
    }

    let selectedValues = _.where(formData, {level: formIndex})[0];
    let high = _.result(selectedValues, "high")
    let low = _.result(selectedValues, "low")
    values.currentLevelPeriod = _.result(selectedValues, "currentLevelPeriod");
    values.high = high ? kitData.properties[sensorIndex].code === "CN" ? high * 1000 : high : high;
    values.low = low ? kitData.properties[sensorIndex].code === "CN" ? low * 1000 : low : low;
    values.persistence = _.result(selectedValues, "persistence");
    values.nextLevelPeriod = _.result(selectedValues, "nextLevelPeriod");
    setShowData(false);
  }, [showData, kitData]);

  useEffect(() => {
    if (!kitData) {
      return;
    }
    setAlertSwitch({alerts: {1: _.result(kitData.alerts, "1")}});
    setMaintains({maintain: kitData.maintain});
    setPersistence({persistence: kitData.persistence})
  }, [kitData]);

  let alertLevels = levelArray(levels.alertLevel);

  function levelArray(x) {
    let level = [];
    for (var i = 1; i <= x; i++) {
      level.push(i);
    }
    return level;
  }

  return (
    <div>
      <Container>
      {!props.kitId ||
        typeof props.kitId === "undefined" ? (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>
              {t("Kit Not Configured")}
            </div>
          </div>
        ) : kitData &&
        kitData.properties &&
        kitData.properties.length > 0 ? (
        <div style={{ border: 0.75, borderColor: "#3F44511" }} className="alert-box">
          <div className={""}>
            {kitData && kitData.properties.map((value, index) => (
              <div
                style={(sensorIndex === value.number && style1) || null}
                key={index}
                className={"r-w-50 plot-report-tabs w-tab-auto"}
                onClick={() => {
                  setSensorIndex(value.number);
                  setIndexSensor(index);
                }}
              >
                <div
                  className="plot-report-tabs-title limit-plot-report-N plot-title-size limit-char-fw"
                  title={getDisplayName(value, kitData.metaData)}
                >
                  {getDisplayName(value, kitData.metaData)}
                </div>
                <div className="plot-report-tabs-value limit-plot-report-V ">
                  {parseInt(value.displayValue, 10)}
                </div>
              </div>
            ))}
          </div>

          <div>
            <div>
              {alertLevels && alertLevels.map((alertData, index) => (
                <div key={index} className={"mt-1"}>
                  <div
                    className={"alert-level-box mb-0 bg-ededed-grey " + (alertData !== formIndex ? "" : " border-bottom m-0")}>
                    <h5 className="d-inline pr-5 plot-alert-heading text-dark">
                      Alert{" "}
                      <span className={"hide-768"}>{"Level"}</span>{" "}
                      {alertData}{" "}
                    </h5>
                    <Switch
                      checked={
                        _.result(kitData.alerts, alertData)
                          ? _.result(kitData.alerts, alertData)
                          : false
                      }
                      onChange={(checked) => {
                        setAlertSwitch({ alerts: { [alertData]: checked } });
                        setAlertUpdate(true);
                      }}
                      onColor="#94acce"
                      offColor="#D3D3D3"
                      onHandleColor="#22a5c6"
                      handleDiameter={12}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={21}
                      className="react-switch"
                      id="material-switch"
                    />

                    {alertLevels.length > 1 && (
                      <a className={"float-right mr-1 ml-auto"}>
                        {" "}
                        {alertData !== formIndex && (
                          <FeatherIcon
                            className={"icon-in-btn plot-alert-btn text-dark border-0"}
                            icon={"chevron-down"}
                            onClick={() => {
                              setFormIndex(alertData);
                              setValues(alertData);
                            }}
                          />
                        )}
                        {alertData === formIndex && (
                          <FeatherIcon
                            className={"icon-in-btn plot-alert-btn text-dark border-0"}
                            icon={"chevron-up"}
                            onClick={() => {
                              setFormIndex(alertData);
                              setValues(alertData);
                            }}
                          />
                        )}
                      </a>
                    )}
                  </div>

                  <div className={!isToggle ? "hide-table" : ""}>
                    <form
                      className="form-controls px-web-32"
                      onSubmit={handleSubmit}
                    >
                      <div className="alert-input-box">
                        <div
                          className={
                            formIndex === 3 ? "col-md-6" : "col-md-4"
                          }
                        >
                          <div className="form-group m-b-16">
                            <label htmlFor="email" className={"add-lable-color"}>
                              {"Alert After"}:{" "}
                            </label>
                            <input
                              onBlur={handleOnBlur}
                              onChange={handleChange}
                              value={values.currentLevelPeriod || ""}
                              type="text"
                              className={`form-control`}
                              name="currentLevelPeriod"
                              placeholder="mins"
                            />
                          </div>
                        </div>
                        <div className={formIndex === 3 ? "col-md-6" : "col-md-4"}>
                          <div className="form-group m-b-16">
                            <label htmlFor="email" className={"add-lable-color"}>
                              {"Persistence Type"}
                            </label>
                            <select
                              onBlur={handleOnBlur}
                              onChange={handleChange}
                              value={values.persistence}
                              className={`form-control`}
                              name="persistence"
                            >
                              <option value={""} hidden>
                                {"Select Time Interval"}
                              </option>

                              <option value="INTERVAL"> {"Interval"}</option>
                              <option value="CONTINUOUS"> {"Continuous"}</option>
                              <option value="SQUARE"> {"Square"}</option>
                            </select>
                          </div>
                        </div>
                        {formIndex !== 3 && (
                          <div className="col-md-4">
                            <div className="form-group m-b-16">
                              <label htmlFor="email" className={"add-lable-color"}>
                                {"Next Alert After"}:{" "}
                              </label>
                              <input
                                onBlur={handleOnBlur}
                                onChange={handleChange}
                                value={values.nextLevelPeriod || ""}
                                type="text"
                                className={`form-control `}
                                name="nextLevelPeriod"
                                placeholder="mins"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mr-5 ml-5 d-block">
                        <InputRange
                          maxValue={getLimits().max}
                          minValue={getLimits().min}
                          step={getLimits().step}
                          formatLabel={(value) => value.toFixed(0)}
                          value={{
                            min: typeof values.low !== "undefined" ? values.low : 0,
                            max: values.high ? values.high : 0,
                          }}
                          onChange={(value) => handleRangeChange(value)}
                        />
                        {errors.high && (
                          <p className="warning-input-msg">{errors.high}</p>
                        )}
                      </div>
                      <div className="text-center m-t-40">
                        <button
                          className="btn btn-sa-secondary p-2 w-140p plot-setting-btn-m"
                          type="reset"
                          onClick={() => setResetForm(true)}
                        >
                          {"RESET"}
                        </button>
                        <button
                          className="btn btn-sa-primary p-2  w-140p"
                          type="submit"
                          onClick={() => {
                            values.level = alertData;
                          }}
                        >
                          {t("button.SAVE")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={"alert-level-box mb-2 threshold bg-ededed-grey " + (!showThreshold ? "" : "border-bottom mb-0")}>
              <h5 className={" d-inline plot-alert-heading text-dark"}>
                {"Threshold History"}
              </h5>{" "}
              <FeatherIcon
                className={"icon-in-btn time-box text-dark mr-1"}
                icon={showThreshold ? "chevron-up" : "chevron-down"}
                onClick={() => setShowThreshold(!showThreshold)}
              />
            </div>
            {showThreshold ? (
              <div className={"sa-table-container border-0"} id="scrollableDivThres">
                <InfiniteScroll style={{ overflow: "none" }}
                  next={fetchDataThreshold}
                  dataLength={thresholdHistory.length}
                  hasMore={true}
                  scrollableTarget="scrollableDivThres"
                  useWindow={false}
                >
                  <table className="table table-borderless sa-table-width">
                    <thead>
                      <tr>
                        <th className={"sa-table-head-sticky"}>{"User"}</th>
                        <th className={"sa-table-head-sticky"}> {"Threshold Level"}</th>
                        <th className={"sa-table-head-sticky"}>{"Alert Level"}</th>
                        <th className={"sa-table-head-sticky"}>{"Time"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thresholdHistory.map((threshold, index) => (
                        <tr key={index}>
                          <td className={'sa-table-data'}>{threshold.user}</td>
                          <td className={'sa-table-data'}>
                            {threshold.alertLimit.low +
                              " to " +
                              threshold.alertLimit.high}
                          </td>
                          <td className={'sa-table-data'}>{threshold.alertLimit.level}</td>
                          <td className={'sa-table-data'}>{toBrowserTimeZone(threshold.time)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {thresholdHistory.length === 0 && (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info" />
                      <div className={"empty-results-text"}>
                        {"There Are No Threshold History"}
                      </div>
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            ) : null}

            <div className="alert-container justify-content-between">
                <div className="col-md-5 w-100 m-b-p-0 pl-0">
                  <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                    <h5 className={"d-inline plot-alert-heading text-dark"}>
                      <span className={"hide-768"}>{("Under")}</span>{" "}
                      {("Maintainance")}
                    </h5>

                    <Switch
                      checked={
                        kitData.maintain ? kitData.maintain : false
                      }
                      onChange={underMaintainSwitch}
                      onColor="#94acce"
                      offColor="#D3D3D3"
                      onHandleColor="#22a5c6"
                      handleDiameter={12}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={21}
                      className="react-switch ml-auto"
                      id="material-switch"
                    />
                  </div>
                  <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                    <h5 className={"d-inline plot-alert-heading text-dark"}>
                      Persistence{" "}
                      <span className={"hide-768"}>{("Device")} </span>
                    </h5>
                    <Switch
                      checked={
                        kitData.persistence
                          ? kitData.persistence
                          : false
                      }
                      onChange={persistenceSwitch}
                      onColor="#94acce"
                      offColor="#D3D3D3"
                      onHandleColor="#22a5c6"
                      handleDiameter={12}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={21}
                      className="react-switch ml-auto"
                      id="material-switch"
                    />
                  </div>
                  <div className="m-b-p-0 pr-0">
                    <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                      <h5 className={"plot-alert-heading text-dark"}>
                        {t("Maintainance History")}
                      </h5>
                    </div>
                    {isMaintenanceHistory.length !== 0 && (
                      <div className="flex-column">
                        <InfiniteScroll
                          next={fetchDataAlertHistory}
                          dataLength={isMaintenanceHistory.length}
                          hasMore={true}
                          scrollableTarget="scrollableDivAlert"
                          useWindow={false}
                        >
                          <table className="table table-borderless sa-table-width">
                            <thead>
                            <tr>
                              <th>User</th>
                              <th>Status</th>
                              <th>Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            {isMaintenanceHistory && isMaintenanceHistory.map((data, index) => (
                              <tr key={index}>
                                <td>{data.user}</td>
                                <td>{data.maintain === true ? "On" : "Off"}</td>
                                <td>{data.time.slice(0, 10)}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                        {isMaintenanceHistory && isMaintenanceHistory === 0 &&
                          <div className={"empty-results mt-5"}>
                            <FeatherIcon icon="info"/>
                            <div className={"empty-results-text"}>There are no Maintenance History</div>
                          </div>
                        }
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-7 m-b-p-0 pr-0">
                  <div className="alert-level-box pl-3 bg-ededed-grey mt-0">
                    <h5 className={"plot-alert-heading text-dark"}>
                      {t("Alert History")}
                    </h5>
                  </div>
                  {alertHistory.length !== 0 && (
                    <div className={"alert-table"} id="scrollableDivAlert">
                      <InfiniteScroll
                        next={fetchDataAlertHistory}
                        dataLength={alertHistory.length}
                        // endMessage={
                        //     <p style={{ textAlign: 'center' }}>
                        //         <b>that's all</b>
                        //     </p>
                        // }
                        // loader={<div className="loader"> Loading... </div>}
                        hasMore={true}
                        scrollableTarget="scrollableDivAlert"
                        useWindow={false}
                      >
                        <table className="table table-borderless sa-table-width">
                          <thead>
                          <tr>
                            <th scope="col">{t("User")}</th>
                            <th scope="col">{t("Level")}</th>
                            <th scope="col">{t("Alert")}</th>
                            <th scope="col">{t("Time")}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {alertHistory.map((history, index) => (
                            <tr key={index}>
                              <td>{history.user} </td>
                              <td>{history.level}</td>
                              <td>{history.alert === true ? "On" : "Off"}</td>
                              <td>{toBrowserTimeZone(history.time)}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  )}
                  {alertHistory.length === 0 && (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>
                        {t("emptyMsg.Alert_History")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
          </div>
        </div>
        ) : (
          <div className={"empty-results"}>
            <FeatherIcon icon="info"/>
            <div className={"empty-results-text"}>
              {formError ? formError : t("Kit Properties Not Available")}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default SettingAlert;
