const INIT_STATE = {
    selectedService: {},
    modules: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "SERVICE_LIST": {
            return {
                ...state,
                modules: action.payload
            };
        }

        case "SELECTED_SERVICE": {
            return {
                ...state,
                selectedService: action.payload
            };
        }

        default:
            return state;

    }
}
