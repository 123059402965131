import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyA9uKFEIqgW63abTdvYTqb3gS6r-60FRpI",
  authDomain: "aaaa-c3d33.firebaseapp.com",
  databaseURL: "https://aaa-c3d33.firebaseio.com",
  projectId: "senzagro-c3d33",
  storageBucket: "senzagro-c3d33.appspot.com",
  messagingSenderId: "283658892245",
  appId: "1:283658892245:web:e206dc51d4a2719e75c35c",
  measurementId: "G-E5NKHDMS7N"
};

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);