import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import * as _ from "underscore";
import locations from './locations';
import { dateFormat, isEmpty, toBrowserTimeZone } from "../shared/utils/utils";
import { saveAs } from 'file-saver';
import axios from "axios";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../shared/actions/setting";

export default function SelectExportColPopup(props) {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState([]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({ aggregation: "SUM", granularity: "HOURS" });
  const [processedNumbers, setProcessedNumbers] = useState([]);
  const newDataArray = [];
  function addData(value) {
    if (selectedList.includes(value)) {
      let removeData = _.without(selectedList, value);
      setSelectedList(removeData);
    } else {
      setSelectedList((oldArray) => [...oldArray, value]);
    }
  }

  const fetchData = async (number) => {
    try {
      let fromDate = props.dateRange ? props.dateRange.fromDate : dateFormat(new Date());
      let toDate = props.dateRange ? props.dateRange.toDate : dateFormat(new Date());

      dispatch(toggleLoader(true));
      if (!processedNumbers.includes(number)) {
        const response = await axios.get(process.env.REACT_APP_HOST + `/core/kit/` + number + '/graph-kit-history/' + 0 + '/summary?from=' + fromDate + '&to=' + toDate +
          (isEmpty(filters) ? '' : '&aggregation=' + (filters.aggregation ? filters.aggregation : "MAX") + '&granularity=' + (filters.granularity ? filters.granularity : "HOURS") + (filters.aggregation === "TIME" ? '&timeOfDay=9' : "")))

        setProcessedNumbers((prevNumbers) => [...prevNumbers, number]);


        if (!isEmpty(response.data.content)) {
          response.data.content.valueX = response.data.content.valueX.map(x => toBrowserTimeZone(x + ':00').substring(0, 16));
          const newDataItem = {
            content: response.data.content,
            location: number
          };
          newDataArray.push(newDataItem);
        }
      }
      dispatch(toggleLoader(false));
    } catch (error) {
      if (error.response && error.response.status === 422) {
      }
    }
  };

  function exportData() {
    Promise.all(selectedList.map((number) => fetchData(number)))
      .then(() => {
        generateCSV();
      })
      .catch((error) => {
        console.error('Error during data fetching:', error);
      });
  }

  function generateCSV() {
    let data = [];
    if (newDataArray && newDataArray.length > 0) {
      for (let i = 0; i < newDataArray.length; i++) {
        if (selectedList.includes(newDataArray[i].location)) {

          for (let j = 0; j < newDataArray[i].content.valueX.length; j++) {
            let location = newDataArray[i].location
            const selectedLabel = locations.find(option => option.value === location)?.label || "Nolimit";

            data.push({
              "location": selectedLabel,
              "time": newDataArray[i].content.valueX[j],
              "value": newDataArray[i].content.valueY[j],
            });
          }
        }
      }
    }

    const currentDate = new Date();
    const formattedCurrentDate = dateFormat(currentDate);
    const filename = `Nolimit_${props.dateRange?.fromDate || formattedCurrentDate}_${props.dateRange?.toDate || formattedCurrentDate}`;
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      filename: filename,
      headers: ['Location', 'Date and Time', 'Count'],
      nullToEmptyString: true,
    };

    try {
      const csvContent = [opt.headers.join(',')].concat(data.map(row => Object.values(row).join(','))).join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `${filename}.csv`);
    } catch (error) {
      console.error('Error during CSV export:', error);
    }
  }

  return (
    <>
      <div className="sa-popup-bg ">
        <div className="sa-popup">
          <form className={'sa-modal-box-style overflowX-hidden'}>
            <div className={'mt-3 mb-2'}>
              <span className={'sa-model-heading'}>Select the locations</span>
              <div className="sa-popup-close-icon"><FeatherIcon onClick={() => {
                props.onClose()
              }} className={"sa-modal-close-icon"} icon={"x"} />
              </div> </div>
            <div className="sa-popup-content">
              <div className={'export-head'}>Select the locations to export CSV file</div>
            </div>
            <div className={'row export-container ml-4 mt-2'}>
              {locations.map((item, index) => (
                <span key={index} className={"col-md-4 p-t-40"}>
                  <label className={'export-checkbox-lable flex'}>
                    <input
                      className={'checkout-box mr-2'}
                      type="checkbox"
                      value={item.value}
                      checked={selectedList.includes(item.value)}
                      onChange={() => addData(item.value)}
                    />
                    <span className={'p-l-10'}>{item.label}</span>
                  </label>
                </span>
              ))}
            </div>
            <div className="sa-popup-btn">
              <button id="btnCancel" type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={() => {
                        props.onClose()
                      }}>
                {t("button.CANCEL")}
              </button>
              <button onClick={(e) => {
                e.preventDefault();
                exportData();
                props.onClose();
              }} id="btnAdd" className="sa-popup-secondary-btn-style">
                {t("button.EXPORT_CSV")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
