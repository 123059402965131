import {getRole} from "../shared/utils/SharedAuthentication";

export function isSuper() {
    return getRole() <= 5;
}

function checkPermission(userAllAccessJson, requestedFeature, access) {
    if (!userAllAccessJson) {
        return false;
    }

    let feature = requestedFeature.split(".");

    if (feature.length === 1) {
        if (access === "VIEW" && userAllAccessJson[feature]) {
            return true;
        } else if (userAllAccessJson[feature] && userAllAccessJson[feature].DEFAULT) {

            return userAllAccessJson[feature].DEFAULT.includes(access);
        }else if (userAllAccessJson[feature]==="FULL_ACCESS") {
            return true;
        } else {
            return false;
        }
    } else {
        let current = feature.shift();
        if (userAllAccessJson[current]==="FULL_ACCESS") {
            return true;
        } else {
            return checkPermission(userAllAccessJson[current], feature.join("."), access);
        }
    }
}

export function hasPermission(feature, access = "VIEW") {
    if(isSuper()){
        return true
    }

    let permission = checkPermission({}, feature, access);

    if (permission) {
        return true;
    }else {
        console.log("asdfasdvasdgfas")
    }
}