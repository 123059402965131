export function validateAddServices(values) {
    let errors = {};

    if (!values.name) {
        errors.name = ' Name is required';
    }
    if (!values.url) {
        errors.url = 'URL is required';
    }
    if (!values.description) {
        errors.description = ' Description is required';
    }
    return errors;
}
export function validateAddModule(values) {
    let errors = {};

    if (!values.name) {
        errors.name = ' Name is required';
    }
    return errors;
}
export function validateAlert(values) {
    let errors = {};
  
    // if (!values.high) {      // 0 also possible value for alert's high
    //   errors.high = 'High is required';
    // }
    return errors;
    //
}