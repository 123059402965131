// optionsData.js

const locations = [
  { value: "nolimit-Image", label: "Wellawatte Image" },
  { value: "nolimit_wellawatte-royal-A", label: "Wellawatte Royal" },
  { value: "nolimit_ccc-A", label: "CCC" },
  { value: "nolimit_dehiwala-ladies-A", label: "Dehiwala Ladies" },
  { value: "nolimit_dehiwala-gents-A", label: "Dehiwala Gents" },
  { value: "nolimit_havelock-city-A", label: "Havelock City" },
  { value: "nolimit_maharagama_A", label: "Maharagama" },
  { value: "nolimit_ogf-A", label: "OGF Kids" },
  { value: "nolimit_ratmalana-A", label: "Ratmalana" },
  { value: "nolimit_rathnapura-A", label: "Rathnapura" },
  { value: "nolimit_kandy-A", label: "Kandy" },
  { value: "nolimit_kandy_kcc-A", label: "Kandy KCC" },
  { value: "nolimit_kurunegala-A", label: "Kurunegala" },
  { value: "nolimit_panadura-A", label: "Panadura" },
  { value: "nolimit_negombo-424-A", label: "Negombo 424" },
  { value: "nolimit_negombo-456-A", label: "Negombo Nolimit" },
  { value: "nolimit_wattala-A", label: "Wattala" },
];

export default locations;
