import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useDispatch} from "react-redux";
import axios from "axios";
import 'c3/c3.css';
import * as _ from "underscore";
import {DateRangePicker} from 'rsuite';
import C3Chart from 'react-c3js';
import * as dateFns from "date-fns";
import {ExportToCsv} from 'export-to-csv';
import FeatherIcon from "feather-icons-react";
import filterHandler from "../shared/utils/FilterHandler";
import { saveAs } from 'file-saver';

import * as d3 from 'd3';
import {useTranslation} from "react-i18next";
import {
  dateAndTimeFormat,
  dateFormat,
  getDisplayName,
  isEmpty,
  timeDurationForGraph,
  timeDurationMinutes,
  toBrowserTimeZone
} from "../shared/utils/utils";
import {getUserId} from "../shared/utils/SharedAuthentication";
import {toggleLoader} from "../shared/actions/setting";
import moment from "moment-timezone";
import SelectExportColPopup from "./select-export-col-popup";

const PlotReport = (props) => {
  const {t, i18n} = useTranslation();
  const [selectedKit, setSelectedKit] = useState({});
  const [kitGraphData, setKitGraphData] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([0]);
  const [dataSet, setDataSet] = useState({});
  const [trendLineGraphData, setTrendLineGraphData] = useState([]);
  const [dateRange, setDataRange] = useState(null);
  const [loadGraph, setLoadGraph] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [valuesX, setValuesX] = useState([]);
  const [valuesY, setValuesY] = useState([]);
  const [toggleIrrigation, setToggleIrrigation] = useState(false);
  const [toggleTrendLine, setToggleTrendLine] = useState(false);
  const [togglePredictionLine, setTogglePredictionLine] = useState(false);
  const [alertLevels, setAlertsLevels] = useState([]);
  const [thresholdLevel, setThresholdLevel] = useState(false);
  const [showGraph, setShowGraph] = useState();
  const [dropDownActive, setDropDownActive] = useState(false);
  const [selectExport, setSelectExport] = useState(false);

  const {handleFilter, setFilter} = filterHandler(
    applyFilter,
    []
  );
  const [filters, setFilters] = useState({aggregation: "SUM", granularity: "HOURS"});

  const dispatch = useDispatch();


  function toggleIrrigationSwitch() {
    setToggleIrrigation(!toggleIrrigation);
  }

  function toggleTrendlineSwitch() {
    setToggleTrendLine(!toggleTrendLine);
  }

  function togglePredictionlineSwitch() {
    setTogglePredictionLine(!togglePredictionLine);
  }

  function toggleThresholdLevelSwitch() {
    setThresholdLevel(!thresholdLevel);
  }

  function getThresholdLevel() {
    let levels = [];
    alertLevels.map((l, i) => {

      let obj = [{}] = [{value: l.high, text: 'Max Threshold Level' + l.level, position: 'middle'},
        {value: l.low, text: 'Min Threshold Level' + l.level, position: 'middle'}];
      levels.push(...obj);
    });
    return [...levels];
  }

  function applyFilter() {
    if (!isEmpty(filters)) {
      setDropDownActive(true);
    } else {
      setDropDownActive(false)
    }
  }

  useEffect(() => {

    if (!props.kitId || !selectedKit.id) {
      return;
    }
    setLoadGraph(false);
    if (selectedIndexes.length === 1) {
      drawGraph([selectedIndexes[0]]);
    } else if (selectedIndexes.length === 2) {
      drawGraph([selectedIndexes[0], selectedIndexes[1]]);
    }

  }, [toggleIrrigation, toggleTrendLine, togglePredictionLine, thresholdLevel, selectedIndexes]);

  function irrigationDataHistory(dataHistory) {
    if (dataHistory.length === 0) {
      return;
    }

    dataHistory.forEach(element => {
      let elapsedTime = timeDurationMinutes(element.startTime, element.stopTime);
      let notableTime = elapsedTime / 1000 / 60;
      let startDateAndTime = new Date(element.startTime);
      let stopDateAndTime = new Date(element.stopTime);
      let deltaNegative = dateAndTimeFormat(startDateAndTime.getTime() - 1000);
      let value0 = dateAndTimeFormat(startDateAndTime.getTime());
      let value1 = dateAndTimeFormat(stopDateAndTime.getTime());
      let deltaPositive = dateAndTimeFormat(stopDateAndTime.getTime() + 1000);


      valuesX.push(deltaNegative, value0, value1, deltaPositive);
      valuesY.push(null, notableTime, notableTime, null);
    });
    setValuesX([...valuesX]);
    setValuesY([...valuesY]);

  }


  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });
  }

  useEffect(() => {
    const dates = {};
    dates.fromDate = dateFormat(props.statisticsDate);
    dates.toDate = dateFormat(props.statisticsDate);
    setDataRange(dates);
  }, [props.statisticsDate]);

  useEffect(() => {
    if (!props.kitId) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + props.kitId)
      .then(function (response) {
        response.data.content.properties = _.filter(response.data.content.properties, function (item) {
          return item.code !== "WI";
        });
        setSelectedKit(response.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        dispatch(toggleLoader(false));
      })
      .finally(() => {
      });

  }, [props.kitId]);


  useEffect(() => {
    if (!props.kitId || !selectedKit.id) {
      return
    }

    selectedKit.properties.map((item, index) => {
      if (index === 0) {
        getHistoryByNumber(item.number)
      } else {
        getHistoryByNumber(item.number, true);
      }
    });
  }, [selectedKit, dateRange, filters]);

  function getHistoryByNumber(number, isSilence) {
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    if (!isSilence) {
      dispatch(toggleLoader(true));
    }

    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + props.kitId + '/graph-kit-history/' + number + '/summary?from=' + fromDate + '&to=' + toDate +
      (isEmpty(filters) ? '' : '&aggregation=' + (filters.aggregation ? filters.aggregation : "MAX") + '&granularity=' + (filters.granularity ? filters.granularity : "HOURS") + (filters.aggregation === "TIME" ? '&timeOfDay=9' : "")))
      .then(function (response) {
        if (!isEmpty(response.data.content)) {

          response.data.content.valueX = response.data.content.valueX.map(x => toBrowserTimeZone(x + ':00').substring(0, 16));
          let index = _.findIndex(kitGraphData, {propertyNumber: number});
          if (index === -1) {
            kitGraphData.push(response.data.content);
          } else {
            kitGraphData[index] = response.data.content;
          }
          setKitGraphData([...kitGraphData]);
          if (selectedIndexes.length === 1) {
            drawGraph([selectedIndexes[0]]);
          } else if (selectedIndexes.length === 2) {
            drawGraph([selectedIndexes[0], selectedIndexes[1]]);
          }
        }
        if (!isSilence) {
          dispatch(toggleLoader(false));
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        if (!isSilence) {
          dispatch(toggleLoader(false));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }

  const style1 = {borderBottom: "solid 0.5px #6CC889"};
  const style2 = {borderBottom: "solid 0.5px #F67F0F"};

  function selectTab(number) {
    let array = [...selectedIndexes];
    const graphData = {};
    graphData.data = null;
    graphData.axis = null;
    setLoadGraph(false);
    if (selectedIndexes.length === 2) {
      if (selectedIndexes.includes(number)) {
        let index = selectedIndexes.findIndex(element => element === number);
        array.splice(index, 1);
      } else {
        array.splice(0, 2);
        array.push(number);
      }
    } else if (selectedIndexes.length === 1) {


      if (!selectedIndexes.includes(number)) {
        array.push(number);
      }
    } else {
      array.push(number);
    }
    setSelectedIndexes(array);

    addDataGraphDate(graphData).then(() => {
      drawGraph(array);
    });
  }


  function drawGraph(array) {
    const graphData = {};
    if (array.length === 1) {
      const value1 = _.findWhere(kitGraphData, {propertyNumber: array[0]});
      const value2 = _.findWhere(trendLineGraphData, {propertyNumber: array[0]});
      let displayName1 = getDisplayName(_.findWhere(selectedKit.properties, {number: array[0]}), selectedKit.metaData);
      let noValue1 = false;
      let noValue2 = false;
      let noValue3 = false;
      let noValue0 = false;
      let value1Max = value1 ? Math.max(...value1.valueY) : 1
      let yValues = [...valuesY];
      let tootltipValuesY = [...valuesY].reverse();
      yValues.map((value, key) => {

        if (value) {
          yValues.splice(key, 1, value1Max);
        }
      });

      if (!value1 || !value1.valueX || !value1.valueY) {

        noValue1 = true;
      }
      if (!value2 || !value2.valueX || !value2.valueT || !toggleTrendLine) {

        noValue2 = true;
      }
      if (!value2 || !value2.valueXP || !value2.valueTP || !togglePredictionLine) {

        noValue3 = true;
      }
      if (!valuesX || !yValues || !toggleIrrigation) {
        noValue0 = true;
      }

      setShowGraph(value1 ? value1.valueX.length : 0);
      const tooltip = {
        format: {
          title: function (x, index) {
            const time = moment(x);
            let formatted = time.format('YYYY-MM-DD HH:mm');
            let nextHour = time.add(1, 'hours')
            let nextHourFormatted = nextHour.format("HH:mm");
            return formatted + " - " + nextHourFormatted;
          },
          value: function (value, ratio, id, index) {
            if (id === 'Irrigation(Duration)') {
              let duration = tootltipValuesY[index];
              return timeDurationForGraph(duration * 60000)
            } else {
              return value.toFixed();
            }

          }
        },
      };

      const data = {
        xs: {
          [displayName1]: 'x',
          'Irrigation(Duration)': 'x0',
          'Trendline': 'x1',
          'Predictionline': 'x2'

        },
        xFormat: '%Y-%m-%d %H:%M',
        columns: [
          ['x'].concat(noValue1 ? [] : value1.valueX),
          ['x0'].concat(noValue0 ? [] : valuesX),
          ['x1'].concat(noValue2 ? [] : value2.valueX).concat(noValue2 ? [] : value2.valueXP[0]),
          ['x2'].concat(noValue3 || toggleTrendLine ? [] : value1.valueX[value1.valueX.length - 1]).concat(noValue3 ? [] : value2.valueXP),
          [displayName1].concat(noValue1 ? [] : value1.valueY),
          ['Irrigation(Duration)'].concat(noValue0 ? [] : yValues),
          ['Trendline'].concat(noValue2 ? [] : value2.valueT).concat(noValue2 ? [] : value2.valueTP[0]),
          ['Predictionline'].concat(noValue3 || toggleTrendLine ? [] : value1.valueY[value1.valueY.length - 1]).concat(noValue3 ? [] : value2.valueTP)


        ],
        type: 'area-spline',
        names: {
          'Date & Time': 'Date & Time',
          'Count Discrete': 'No Of Customers',
        },
        types: {
          'Irrigation(Duration)': 'area',
          // 'Trendline': 'area',
        },

      };

      const zoom = {
        rescale: true

      };

      const grid = {
        y: {
          lines: thresholdLevel && selectedIndexes.length === 1 ? getThresholdLevel : []
        }
      };

      const axis = {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            multiline: true,
            format: '%Y-%m-%d %H:%M',
            count: 8,
            fit: true,
            rotate: window.innerWidth > 576 ? 0 : 9,
            culling: {
              max: window.innerWidth > 480 ? 10 : 5,
            },
            height: 100,


          },
          label: {
            text: 'Date & Time',
            position: 'outer-center',
          },

        },
        y: {
          label: {
            text: 'No Of Customers',
            position: 'outer-middle',

          }
        },
      };
      graphData['data'] = data;
      graphData['axis'] = axis;
      graphData['tooltip'] = tooltip;
      graphData['zoom'] = zoom;
      graphData['grid'] = grid;

    } else if (array.length === 2) {

      let yValues = [...valuesY];
      let tootltipValuesY = [...valuesY].reverse();

      const value1 = _.findWhere(kitGraphData, {propertyNumber: array[0]});
      const value2 = _.findWhere(kitGraphData, {propertyNumber: array[1]});

      let noValue1 = false;
      let noValue2 = false;
      let noValue0 = false;

      let displayName1 = getDisplayName(_.findWhere(selectedKit.properties, {number: array[0]}), selectedKit.metaData);
      let displayName2 = getDisplayName(_.findWhere(selectedKit.properties, {number: array[1]}), selectedKit.metaData);
      if (displayName1 === displayName2) {
        displayName2 = displayName2 + "1";
      }
      const xs = {};
      xs[displayName1] = 'x1';
      xs[displayName2] = 'x2';
      const axes = {};
      axes[displayName1] = 'y';
      axes[displayName2] = 'y2';

      let value1Max = value1 ? Math.max(...value1.valueY) : 1;
      let value2Max = value2 ? Math.max(...value2.valueY) : 1;
      yValues.map((value, key) => {

        if (value) {
          if (value1Max > value2Max) {
            yValues.splice(key, 1, value1Max);
          } else {
            yValues.splice(key, 1, value2Max);
          }
        }
      });

      if (!valuesX || !yValues || !toggleIrrigation) {
        noValue0 = true;
      }
      if (!value1 || !value1.valueX || !value1.valueY) {
        noValue1 = true;
      }
      if (!value2 || !value2.valueX || !value2.valueY) {
        noValue2 = true;
      }


      const tooltip = {
        format: {
          value: function (value, ratio, id, index) {
            if (id === 'Irrigation(Duration)') {
              let duration = tootltipValuesY[index];
              return timeDurationForGraph(duration * 60000)
            } else {
              return value;
            }

          }
        },
      };

      const zoom = {
        rescale: true

      };

      const data = {
        xs: {
          [displayName1]: 'x1',
          [displayName2]: 'x2',
          'Irrigation(Duration)': 'x0'

        },
        xFormat: '%Y-%m-%d %H:%M',
        columns: [
          ['x0'].concat(noValue0 ? [] : valuesX),
          ['x1'].concat(noValue1 ? [] : value1.valueX),
          ['x2'].concat(noValue2 ? [] : value2.valueX),
          ['Irrigation(Duration)'].concat(noValue0 ? [] : yValues),
          [displayName1].concat(noValue1 ? [] : value1.valueY),
          [displayName2].concat(noValue2 ? [] : value2.valueY),
        ],
        types: {
          'Irrigation(Duration)': 'area',
        },
      };

      let axis = {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            multiline: true,
            format: '%Y-%m-%d %H:%M',
            count: 8,
            fit: true
          },
          label: {
            text: 'Date & Time',
            position: 'outer-center',
          },
          fit: true,
          culling: {
            max: 10,
          }
        },
        y: {
          label: {
            text: 'No Of Customers',
            position: 'outer-middle'
          },
        },
        y2: {
          show: true,
          label: {
            text: 'No Of Customers',
            position: 'outer-middle'
          },
        }
      };
      graphData['data'] = data;
      graphData['axis'] = axis;
      graphData['tooltip'] = tooltip;
      graphData['zoom'] = zoom;

    } else {
      let data = {};
      let axis = {};

      graphData['data'] = data;
      graphData['axis'] = axis;
    }
    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);
      let elem = document.getElementById("scroll");
      elem && elem.scrollIntoView();
    });
  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);

  }

  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-34px");
    } else {
      d3.select(".c3-axis-y-label").attr("dy", "-42px");
    }
    d3.selectAll(".c3-legend-item").attr("x", "400");

  }

  return (
    <div>
      {!props.kitId &&
        <div className={"empty-results"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>{t("emptyMsg.KIT_NOT_CONFIGURED")}</div>
        </div>}
      {props.kitId &&

        <div>
          <div className={'row justify-content-between'} style={{padding: "8px 16px"}}>
            <div className={"header-text"}>Customer Count
            </div>
            <div className={'row justify-content-between widget-container'}>
              <button
                 //onClick={exportData}
                      onClick={() => {
                        setSelectExport(true);
                      }}
                      className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right">
                <FeatherIcon
                  icon={"upload"} className={"sa-table-icon-size"}/> <span
                className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
              </button>
              {/*<select className={"view-filter align-items-center show-768 mb-3"} defaultValue={"Total View"}>*/}
              {/*  <option value="Total View">{"Total View"}</option>*/}
              {/*</select>*/}
              <div hidden={selectedIndexes.length === 0 || !props.kitId}>
                <div className={"plot-report-filter"}>
                  <DateRangePicker key={dateRange ? `${dateRange.fromDate}-${dateRange.toDate}` : 'defaultKey'}
                                   disabledDate={date => date > new Date()} disabled={false}
                                   onChange={(e) => onCheck(e)}
                                   oneTap={false} onOk={(e) => onCheck(e)} cleanable={false}
                                   placement="left"
                                   showWeekNumbers={true} appearance={"default"} placeholder={dateRange ? dateRange.fromDate + "~" + dateRange.toDate : "Today"}
                                   ranges={[{
                                     label: 'Today',
                                     value: [new Date(), new Date()]
                                   }, {
                                     label: 'Yesterday',
                                     value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                   }, {
                                     label: 'Last 7 days',
                                     value: [dateFns.subDays(new Date(), 6), new Date()]
                                   }, {
                                     label: 'Last 30 days',
                                     value: [dateFns.subDays(new Date(), 30), new Date()]
                                   }]}
                  />


                </div>
              </div>
            </div>
          </div>
          {showGraph === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.NO_DATA_FOR_DATE_RANGE")}</div>
            </div>)
          }
          <div id={"scroll"} style={{marginLeft: "-15px"}}>
            {showGraph > 0 && selectedIndexes.length >= 1 && loadGraph && dataSet.data && (
              <C3Chart area={{zerobased: false}} tooltip={dataSet.tooltip} padding={{left: 51}} data={dataSet.data}
                       zoom={dataSet.zoom} subchart={{show: true}} axis={dataSet.axis} grid={dataSet.grid}
                       onrendered={styleGraph}/>)}
          </div>
          <div hidden={kitGraphData.length > 0 || !props.kitId} className={"empty-results"}>
            <div className={"empty-results-text"}>{t("emptyMsg.NO_DATA")}</div>
          </div>
        </div>
      }
      {selectExport &&
        <SelectExportColPopup dateRange={dateRange} onClose={() => {
          setSelectExport(false)
        }}
                              // item={exportDataCol}
        />
      }
    </div>
  );

};


export default PlotReport
