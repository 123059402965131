import {combineReducers} from 'redux';
import isLoaderCountReducer from "../shared/reducers/setting"
import settingReducer from "../shared/reducers/setting"
import serviceListReducer from "./service"

const reducers = combineReducers({
    setting: settingReducer,
    isLoaderCount: isLoaderCountReducer,
    modules: serviceListReducer,

});

export default reducers